exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-bus-js": () => import("./../../../src/templates/bus.js" /* webpackChunkName: "component---src-templates-bus-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-payment-page-js": () => import("./../../../src/templates/paymentPage.js" /* webpackChunkName: "component---src-templates-payment-page-js" */),
  "component---src-templates-success-js": () => import("./../../../src/templates/success.js" /* webpackChunkName: "component---src-templates-success-js" */)
}

