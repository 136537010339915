import React from "react";
import GlobalContextProvider from "./src/context/global-context-provider";
import "./src/style/global.css";
import ReactDOM from "react-dom/client";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};
