import React from "react";
import PropTypes from "prop-types";

const initialState = {
  cartRevision: 0,
  cartExternalRevision: -1,
  cartSyncInProgress: false,
  cart: [],
  calculator: {
    roundWay: "twoway",
    activityRequirements: {
      isValid: true,
      hasWheelchair: false,
      hasTrailer: false,
      wheelchair: {
        assistance: undefined,
        properties: {},
      },
    },
    foodDeparture: [],
    foodReturn: [],
    busPrices: [],
    selectedBus: undefined,
    selectedBusName: undefined,
    selectedBusFuelUsage: undefined,
    estimatedPrices: {
      food: undefined,
      toll: undefined,
      wheelchair: undefined,
      trailer: undefined,
      bus: undefined,
      total: undefined,
    },
  },
  user: null,
  isLoggedIn: undefined,
  userRefresh: 0,
  pageCache: {},
  quoteInput: {
    numPassengers: 15,
  },
  quote: {},
  quoteStatus: { inProgress: false, error: false },
  locationInputCache: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART": {
      const newProduct = action.value;
      const alreadyInCart =
        state.cart.find((product) => product.id === newProduct.id) !==
        undefined;
      if (alreadyInCart) {
        return {
          ...state,
          cartRevision: state.cartRevision + 1,
          cart: state.cart.map((product) =>
            product.id === newProduct.id
              ? { ...product, quantity: product.quantity + 1 }
              : product
          ),
        };
      } else {
        return {
          ...state,
          cartRevision: state.cartRevision + 1,
          cart: [...state.cart, { ...newProduct, quantity: 1 }],
        };
      }
    }
    case "REMOVE_FROM_CART": {
      const oldProduct = action.value;
      return {
        ...state,
        cartRevision: state.cartRevision + 1,
        cart: state.cart
          .map((product) =>
            product.id === oldProduct.id
              ? { ...product, quantity: product.quantity - 1 }
              : product
          )
          .filter((product) => product.quantity > 0),
      };
    }
    case "UPDATE_CART": {
      if (action.value.length === 0) {
        window.localStorage.removeItem("woo-session");
        window.localStorage.removeItem("local_cart");
      }
      return {
        ...state,
        cartRevision: state.cartRevision + 1,
        cart: action.value,
      };
    }
    case "SYNC_CART": {
      return {
        ...state,
        cartSyncInProgress: action.cartSyncInProgress,
        cartExternalRevision: action.cartExternalRevision,
      };
    }
    case "SET_USER": {
      return {
        ...state,
        user: action.value,
        isLoggedIn: action.value?.nicename !== undefined,
      };
    }
    case "CHECK_USER": {
      return {
        ...state,
        userRefresh: state.userRefresh + 1,
      };
    }
    case "CACHE_PAGE": {
      const newState = { ...state };
      newState.pageCache[action.uri] = action.data;
      return newState;
    }
    case "CACHE_LOCATION_INPUT": {
      const newState = { ...state };
      newState.locationInputCache[action.input] = action.data;
      return newState;
    }
    case "UPDATE_CALCULATOR": {
      return {
        ...state,
        calculator: {
          ...state.calculator,
          ...action.value,
        },
        quoteInput: {
          ...(action.value.roundWay !== "oneway"
            ? state.quoteInput
            : ((oldQuoteInput) => {
                // remove twoway-specific quoteInputs
                const newQuoteInput = { ...oldQuoteInput };
                delete newQuoteInput["returnDate"];
                delete newQuoteInput["returnWaypoints"];
                return newQuoteInput;
              })(state.quoteInput)),
        },
      };
    }
    case "UPDATE_QUOTE_INPUT": {
      return {
        ...state,
        quoteInput: {
          ...state.quoteInput,
          ...action.value,
        },
      };
    }
    case "UPDATE_QUOTE": {
      return {
        ...state,
        quote: action.value,
      };
    }
    case "UPDATE_QUOTE_STATUS": {
      return {
        ...state,
        quoteStatus: {
          ...state.quoteStatus,
          ...action.value,
        },
      };
    }
    default:
      throw new Error(`Invalid action: ${action.type}`);
  }
};

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("local_cart") || "[]");
    if (Array.isArray(cart)) {
      dispatch({
        type: "UPDATE_CART",
        value: cart,
      });
    }
  }, []);

  React.useEffect(() => {
    if (state.cartRevision > 0) {
      localStorage.setItem("local_cart", JSON.stringify(state.cart));
    }
  }, [state.cartRevision]);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node,
};

export default GlobalContextProvider;
